import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Theme.vue'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue'),
        meta: {
          layout: 'home'
        }
      },
      {
        path: '/game/:id',
        name: 'game',
        component: () => import('../views/game/platyList.vue'),
      },
      {
        path: '/gameList',
        name: 'gameList',
        component: () => import('../views/game/gameList.vue')
      },
      {
        path: '/user/deposit',
        name: 'deposit',
        component: () => import('../views/user/deposit/mode.vue')
      },
      {
        path: '/user/deposit/order',
        name: 'order',
        component: () => import('../views/user/deposit/order.vue')
      },
      {
        path: '/user/bank/index',
        name: 'auth',
        component: () => import('../views/user/bank/index.vue')
      },
      {
        path: '/user/security/index',
        name: 'security',
        component: () => import('../views/user/security/index.vue'),
        redirect: '/user/security/email',
        children: [
          {
            path: '/user/security/email',
            name: 'email',
            meta: {
              type: 'email'
            },
            component: () => import('../views/user/security/email.vue'),
          },
          {
            path: '/user/security/phone',
            name: 'phone',
            meta: {
              type: 'phone'
            },
            component: () => import('../views/user/security/phone.vue'),
          },
          {
            path: '/user/security/modifyPwd',
            name: 'modifyPwd',
            meta: {
              type: 'modifyPwd'
            },
            component: () => import('../views/user/security/modifyPwd.vue'),
          },
          {
            path: '/user/security/withdrawPwd',
            name: 'withdrawPwd',
            meta: {
              type: 'withdrawPwd'
            },
            component: () => import('../views/user/security/withdrawPwd.vue'),
          },
          {
            path: '/user/security/information',
            name: 'information',
            meta: {
              type: 'information'
            },
            component: () => import('../views/user/security/information.vue'),
          }
        ]
      },
      {
        path: '/user/withdraw/index',
        name: 'withdraw',
        component: () => import('../views/user/withdraw/index.vue')
      },
      {
        path: '/user/transfer/index',
        name: 'transfer',
        component: () => import('../views/user/transfer/index.vue')
      },
      {
        path: '/user/records/index',
        name: 'records',
        component: () => import('../views/user/records/index.vue')
      },
      {
        path: '/user/betRecord/index',
        name: 'betRecord',
        component: () => import('../views/user/betRecord/index.vue')
      },
      {
        path: '/user/welfare/index',
        name: 'welfare',
        component: () => import('../views/user/welfare/index.vue')
      },
      {
        path: '/user/index',
        name: 'userInfo',
        component: () => import('../views/user/index.vue'),
        meta: {
          type: 'userInfo'
        },
      },
      {
        path: '/user/feedback',
        name: 'feeback',
        component: () => import('../views/user/feedback/index.vue'),
        meta: {
          type: 'feeback'
        },
      },
      {
        path: '/user/feedback/list',
        name: 'feebackList',
        component: () => import('../views/user/feedback/list.vue'),
        meta: {
          type: 'list'
        },
      },
      {
        path: '/user/feedback/detail',
        name: 'feebackDetail',
        component: () => import('../views/user/feedback/detail.vue'),
        meta: {
          type: 'detail'
        },
      },
      {
        path: '/franchise',
        name: 'franchise',
        component: () => import('../views/franchise/index.vue'),
        meta: {
          layout: 'franchise'
        },
      },
      {
        path: '/user/mail',
        name: 'mail',
        component: () => import('../views/user/mail/mail.vue'),
        meta: {
          layout: 'mail'
        },
      }, ,
      {
        path: '/download',
        name: 'download',
        component: () => import('../views/download/index.vue'),
        meta: {
          layout: 'download'
        },
      },
      {
        path: '/brand',
        name: 'brand',
        component: () => import('../views/brand/index.vue'),
        meta: {
          layout: 'brand'
        },
      },
      {
        path: '/about/index',
        name: 'about',
        component: () => import('../views/about/index.vue'),
        meta: {
          layout: 'about'
        },
      },
      {
        path: '/about/agreement',
        name: 'agreement',
        component: () => import('../views/about/agreement.vue'),
        meta: {
          layout: 'agreement'
        },
      },
      {
        path: '/about/privacypolicy',
        name: 'privacypolicy',
        component: () => import('../views/about/privacypolicy.vue'),
        meta: {
          layout: 'privacypolicy'
        },
      },
      {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/notice.vue'),
        meta: {
          layout: 'notice'
        },
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/activity/list.vue'),
    meta:{
      layout:'activity'
    }
  },
  {
    path: '/activity/detail',
    name: 'activityDetail',
    component: () => import('../views/activity/detail.vue'),
    meta:{
      layout:'activity'
    }
  },
  {
    path: '/user/update',
    name: 'update',
    component: () => import('../views/user/vip/update.vue')
  },
  {
    path: '/user/vip',
    name: 'introduce',
    component: () => import('../views/user/vip/index.vue')
  },
  {
    path: '/user/vip/detail',
    name: 'detail',
    component: () => import('../views/user/vip/detail.vue')
  },
  {
    path: '/user/vip/exclusive',
    name: 'exclusive',
    component: () => import('../views/user/vip/exclusive.vue')
  },
  {
    path: '/user/vip/secretary',
    name: 'secretary',
    component: () => import('../views/user/vip/secretary.vue')
  },
  {
    path: '/user/vip/journey',
    name: 'journey',
    component: () => import('../views/user/vip/journey.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('../views/invite/index.vue')
  },
  {
    path: '/van',
    name: 'van',
    component: () => import('../views/brand/van.vue'),
    meta: {
      footer: true
    }
  },
  {
    path: '/eden',
    name: 'eden',
    component: () => import('../views/brand/eden.vue'),
    meta: {
      footer: true
    }
  },
  {
    path: '/brand/detailImg',
    name: 'van',
    component: () => import('../views/brand/detailImg.vue')
  }
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
