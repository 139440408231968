import Vue from "vue";
import Vuex from "vuex";

import { getToken } from "@/utils/token";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: !!getToken() || false, //是否登陆
    userInfo: localStorage.getItem("userInfo") || null,//个人资料
    message: 0, //未读消息数量
    accountMoney: null,//账户钱
    baseInfo: null,//基础信息
    sms: false,
    isBankLength: false,
    languages: [
      { type: 'zh', id: '1' },
      { type: 'hk', id: '2' },
      { type: 'en', id: '3' }
    ],
    regAuth: null,
    hotsGameList: null,
    isOpenLogin: false,
    registerTips: null,
    navList: [],
    smsSwitch: false,
    apliyPwd:false,
  },
  getters: {},
  mutations: {
    setHasLogin(state, val) {
      state.isLogin = val;
    },
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    setMessage(state, val) {
      state.message = val;
    },
    setBaseInfo(state, val) {
      state.baseInfo = val;
    },
    setSms(state, val) {
      state.sms = val;
    },
    setIsBankLength(state, val) {
      state.isBankLength = val;
    },
    setRegAuth(state, val) {
      state.regAuth = val;
    },
    setHotsGameList(state, val) {
      state.hotsGameList = val;
    },
    setOpenLogin(state, val) {
      state.isOpenLogin = val;
    },
    setRegisterTips(state, val) {
      state.registerTips = val;
    },
    setNavList(state, val) {
      state.navList = val;
    },
    setSmsSwitch(state, val) {
      state.smsSwitch = val;
    },
    setAccountMoney(state, val) {
      state.accountMoney = val;
    },
    setApliyPwd(state,val){
      state.apliyPwd = val;
    },
  },
  actions: {
    setUserInfoAc(context, val) {
      context.commit("setUserInfo", val);
    },
    setHasLoginAc(context, val) {
      context.commit("setHasLogin", val);
    },
    setMessageAc(context, val) {
      context.commit("setMessage", val);
    },
    setBaseInfoAC(context, val) {
      context.commit("setBaseInfo", val);
    },
    setSmsAc(context, val) {
      context.commit("setSms", val);
    },
    setIsBankLengthAc(context, val) {
      context.commit("setIsBankLength", val);
    },
    setRegAuthAc(context, val) {
      context.commit("setRegAuth", val);
    },
    setHotsGameListAc(context, val) {
      context.commit("setHotsGameList", val);
    },
    setOpenLoginAc(context, val) {
      context.commit("setOpenLogin", val);
    },
    setRegisterTipsAc(context, val) {
      context.commit("setRegisterTips", val);
    },
    setNavListAc(context, val) {
      context.commit("setNavList", val);
    },
    setSmsSwitchAc(context, val) {
      context.commit("setSmsSwitch", val);
    },
    setAccountMoneyAc(context, val) {
      context.commit("setAccountMoney", val);
    },
    setApliyPwdAc(context, val){
      context.commit("setApliyPwd", val);
    },
  },
  modules: {},
});
