import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from '@/mixins/index.js'
import ElementUI from 'element-ui';
import VueLazyload from 'vue-lazyload';
import 'element-ui/lib/theme-chalk/index.css';
import loadong from './utils/loading'
import api from "@/api/index";
import '@/assets/css/all.scss'

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$Api = api;
Vue.use(loadong)
Vue.use(VueLazyload, {
  preload: 1.3,
  loading: require('@/assets/pic/imgPng.png'),
  error: require('@/assets/pic/imgPng.png'),
  attempt: 3,
})
Vue.mixin(mixins)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
