<template>
    <div class="zl-footer">
        <div class="footer-h3">欧宝·荣誉赞助</div>
        <div class="footer">
            <ul>
                <li v-for="(item,index) in footer" :key="index">
                    <img :src="item.img" />
                    <span>{{ item.name }}</span>
                    <p>{{ item.copy }}</p>
                </li>
            </ul>
        </div>
        <div class="footer-copy">
            <img src="@/assets/pic/footer/logo.webp" />
            <span @click="openUrl('/about/index')">关于欧宝</span>
            <span @click="openUrl('/about/agreement')">规则与条例</span>
            <span @click="openUrl('/about/privacypolicy')">隐私政策</span>
            <span>© 2024 欧宝 版权所有. All rights Reserved</span>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            footer:[
                {
                    name:'德甲勒沃库森',
                    copy:'2018-2022高级赞助商',
                    img:require('@/assets/pic/footer/01.webp')
                },
                {
                    name:'英冠维冈竞技',
                    copy:'2019-2020官方主赞助商',
                    img:require('@/assets/pic/footer/02.webp')
                },
                {
                    name:'巴甲弗鲁米嫩塞',
                    copy:'2019-2020高级赞助商',
                    img:require('@/assets/pic/footer/03.png')
                },
                {
                    name:'德甲奥格斯堡',
                    copy:'2020官方合作伙伴',
                    img:require('@/assets/pic/footer/04.png')
                },
                {
                    name:'荷甲阿贾克斯',
                    copy:'2017-2019高级赞助商',
                    img:require('@/assets/pic/footer/05.png')
                },
                {
                    name:'意甲罗马',
                    copy:'2017-2018赛季高级赞助商',
                    img:require('@/assets/pic/footer/06.png')
                },
                {
                    name:'ONE冠军赛',
                    copy:'2015新加坡站赞助商',
                    img:require('@/assets/pic/footer/07.png')
                },
                {
                    name:'英超女王公园巡游者',
                    copy:'2012-2013赛季高级赞助商',
                    img:require('@/assets/pic/footer/08.webp')
                }
            ]
        }
    }
}
</script>
<style scoped lang="scss">
.zl-footer{
    border-top: 1px solid #333;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer-h3{
    background-image: -webkit-linear-gradient(90deg, #585858, #757575 82%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #757575;
    font-size: 16px;
    padding: 24px 0 15px;
}
.footer{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #262626;
    ul{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    li{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            opacity: .5;
        }
        span{   
            font-size: 13px;
            color: #626262;
            white-space: nowrap;
            margin-top: 10px;
        }
        p{
            font-size: 10px;
            color: #4a4a4a;
            white-space: nowrap;
        }
        &:hover{
            img{
                opacity: 1;
            }
        }
    }
}
.footer-copy{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    img{
        height: 20px;
        margin-right: 40px;
    }
    span{
        margin-right: 30px;
        padding-right: 30px;
        color: #585858;
        font-size: 13px;
        position: relative;
        cursor: pointer;
        &::after{
            content: "";
            margin: auto;
            width: 1px;
            height: 12px;
            background-color: #282b30;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
        }
        &:hover{
            color: #fff;
        }
        &:last-child{
            cursor: default;
            &:hover{
                color: #585858;
            }
            &::after{
                display: none;
            }
        }
    }
}
</style>