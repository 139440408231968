<template>
  <div id="app">
    <Headers></Headers>
    <router-view />
    <Footer v-if="!$route.meta.footer"></Footer>
    <div class="zl-uptop" v-if="isUptop" @click="scrollTopVal">
      <span class="ico-top"></span>
      <p>返回<br />顶部</p>
    </div>
    <Login v-if="isOpenLogin" :visible.sync="isOpenLogin" />
    <div class="online-live" :class="{ onlineOpen: isOnline }">
      <div class="row" @click="openContact(baseInfo[6].configValue)">
        <span class="ico-service icon"></span>
        <div class="info">在线客服</div>
      </div>
      <!-- <div class="row" v-if="baseInfo">
        <span class="ico-phone icon"></span>
        <div class="info">
          电话回拨
          <p>{{ baseInfo[14].configValue }}</p>
        </div>
      </div>
      <div class="row">
        <span class="ico-qrcode icon"></span>
        <div class="info">下载APP</div>
      </div> -->
      <div class="link" @click="isOnline = !isOnline">
        {{ isOnline ? "展开" : "收起" }}
      </div>
    </div>
  </div>
</template>
<script>
import Login from "@/components/Login.vue";
import Headers from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Headers,
    Footer,
    Login,
  },
  data() {
    return {
      isOnline: true,
      isUptop: false,
    };
  },
  computed: {
    ...mapState(["isOpenLogin", "baseInfo"]),
  },
  mounted() {
    this.getBaseInfo();
    this.getRegisterItem();
    window.addEventListener("scroll", this.getUpTop);
  },
  watch: {
    $route() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  },
  methods: {
    scrollTopVal() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    getUpTop() {
      if (window.scrollY > 100) {
        this.isUptop = true;
      } else {
        this.isUptop = false;
      }
    },
    //注册事项以及手机号码是否显示
    getRegisterItem() {
      this.$Api.getRegisterItem().then((res) => {
        if (res) {
          this.$store.dispatch("setRegisterTipsAc", res.registerItemList);
        }
      });
    },
    // 网站基础信息如：ico、title、域名等
    getBaseInfo() {
      // this.$loading.show()
      this.$Api.getWebsiteBasicInfo().then((res) => {
        // this.$loading.hide()
        if (res) {
          const baseInfo = res.websiteBasicList;
          this.$store.dispatch("setBaseInfoAC", baseInfo);
          this.exitIco(baseInfo);
        }
      });
    },
    // 设置ico、title等信息
    exitIco(val) {
      let ico = document.querySelector('link[rel="icon"]');
      let title = document.querySelector("title");
      title.innerHTML = val[0].configValue;
      if (ico !== null) {
        ico.href = val[1].configValue;
      } else {
        ico = document.createElement("link");
        ico.rel = "icon";
        ico.href = val[1].configValue;
        document.head.appendChild(ico);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/css/theme.scss";
.zl-uptop {
  position: fixed;
  right: 80px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 22px;
    color: #4a4a4a;
  }
  p {
    font-size: 13px;
    margin-top: 6px;
    color: #95979f;
    line-height: 1.4;
  }
}
.online-live {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(30%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.4s;
  width: 185px;
  &.onlineOpen {
    width: 50px;
    overflow: hidden;
  }
  .row {
    display: flex;
    align-items: center;
    background: #000;
    border-radius: 4px;
    cursor: pointer;
    width: 185px;
    .icon {
      width: 50px;
      height: 50px;
      font-size: 29px;
      background-image: -webkit-linear-gradient(74deg, #a0511a, #ff7f27);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .info {
      color: $main;
      font-size: 14px;
      p {
        color: white;
      }
    }
  }
  .link {
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
}
</style>
