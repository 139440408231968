<template>
  <div class="login" ref="loginPop">
    <div class="login-box" :class="{ 'transition-up-center': isOpenLogin }">
      <div class="login-close ico-close-circle" @click="closeLogin"></div>
      <div class="login-banner">
        <div class="regiter-guide">
          <p>
            如果登录遇到问题，请
            <span v-if="baseInfo" @click="openContact(baseInfo[6].configValue)"
              >联系客服</span
            >
          </p>
          <div>还未创建账户？点击 <span @click="registerJupm">注册</span></div>
        </div>
        <el-carousel
          :interval="5000"
          arrow="never"
          height="450px"
          indicator-position="none"
        >
          <el-carousel-item v-for="item in 2" :key="item">
            <img
              :src="require('@/assets/pic/regiter0' + item + '.webp')"
              class="login-img"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="login-from">
        <div class="login-from-box">
          <div class="login-from-h2">用户登录</div>
          <div
            class="login-item"
            :class="{
              hover: from.account || targetMap === 'account',
              line: targetMap === 'account',
            }"
          >
            <div class="login-input">
              <div class="login-item-title">账号</div>
              <input
                type="text"
                v-model="from.account"
                :placeholder="fromPle['account'].text"
                @focus="moveTitle('account')"
                @blur="moveOut"
              />
            </div>
            <span
              class="ico-close-o"
              v-if="from.account"
              @click="clearInput('account')"
            ></span>
          </div>
          <div
            class="login-item"
            :class="{
              hover: from.password || targetMap === 'password',
              line: targetMap === 'password',
            }"
          >
            <div class="login-input">
              <div class="login-item-title">密码</div>
              <input
                :type="inputType"
                v-model="from.password"
                :placeholder="fromPle['password'].text"
                @focus="moveTitle('password')"
                @blur="moveOut"
              />
            </div>
            <span
              class="ico-close-o"
              v-if="from.password"
              @click="clearInput('password')"
            ></span>
            <span
              class="ico-eye-close"
              :class="{ eye: inputType === 'text' }"
              @click="eyeInput"
            ></span>
          </div>
          <div
            class="login-item"
            v-if="registerTips && registerTips[12].isShow"
            :class="{
              hover: from.code || targetMap === 'code',
              line: targetMap === 'code',
            }"
          >
            <div class="login-input">
              <div class="login-item-title">验证码</div>
              <input
                type="text"
                maxlength="6"
                v-model="from.code"
                :placeholder="fromPle['code'].text"
                @focus="moveTitle('code')"
                @blur="moveOut"
              />
            </div>
            <span
              class="ico-close-o"
              v-if="from.code"
              @click="clearInput('code')"
            ></span>
            <img v-lazy="codeImg" @click="getCaptcha" class="img-code" />
          </div>
          <div class="login-tips">
            <label class="label" :class="{ hover: isJZ }" @click="isJZ = !isJZ"
              ><span class="ico-checkbox"></span>记住用户名</label
            >
            <div class="forgot" @click="openContact(baseInfo[6].configValue)">
              忘记密码?
            </div>
          </div>
          <div class="login-link">
            <el-button type="primary" class="hei50" @click="loginSend"
              >登录</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { setToken } from "@/utils/token";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      from: {
        account: "",
        password: "",
        code: "",
      },
      fromPle: {
        account: { text: "账号" },
        password: { text: "密码" },
        code: { text: "验证码" },
      },
      targetMap: "",
      inputType: "password",
      codeImg: "",
      isJZ: true,
    };
  },
  computed: {
    ...mapState(["isOpenLogin", "registerTips", "baseInfo"]),
  },
  mounted() {
    this.getCaptcha();
    this.initInfo();
  },
  methods: {
    registerJupm() {
      this.$router.push("/register");
      this.closeLogin();
    },
    initInfo() {
      const dom = this.$refs.loginPop;
      document.body.appendChild(dom);
      const account = localStorage.getItem("account");
      if (account) {
        this.from.account = account;
      }
    },
    // 关闭
    closeLogin() {
      this.$store.dispatch("setOpenLoginAc", false);
    },
    // 控制title
    moveTitle(val) {
      this.targetMap = val;
      this.fromPle[val].text = "";
    },
    // 离开还原
    moveOut() {
      this.targetMap = undefined;
      this.fromPle = {
        account: { text: "账号" },
        password: { text: "密码" },
        code: { text: "验证码" },
      };
    },
    // 清除input内容
    clearInput(val) {
      if (val === "account") {
        this.from.account = "";
      }
      if (val === "password") {
        this.from.password = "";
      }
      if (val === "code") {
        this.from.code = "";
      }
    },
    // 查看密码
    eyeInput() {
      if (this.inputType === "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    async getCaptcha() {
      this.codeUUid = uuidv4();
      let res = await this.$Api.getCaptcha(this.codeUUid);
      let imgUrl =
        "data:image/png;base64," +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
      this.codeImg = imgUrl;
    },
    // 提交登录
    loginSend() {
      const { account, password, code } = this.from;
      let params = {
        userName: account,
        password: password,
        code: code,
        codeId: this.codeUUid,
      };
      this.$Api.loginSend(params).then((res) => {
        if (res) {
          this.$store.dispatch("setHasLoginAc", true);
          setToken(res.token);
          this.$message({
            message: "登录成功",
            type: "success",
          });
          if (this.isJZ) {
            localStorage.setItem("account", account);
          }
          this.closeLogin();
          return;
        }
        this.getCaptcha();
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/css/theme.scss";

.login-enter-from {
  opacity: 0;
}

.login-enter-active {
  transition: all 0.3s ease-in;
}

.login-enter-to {
  opacity: 1;
}

.login {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 30;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-box {
    display: flex;
    align-items: center;
    width: 900px;
    height: 450px;
    position: relative;
    opacity: 0;
    transform: translateY(100%);
  }

  &-banner {
    width: 321px;
    height: 450px;
    background: #000;
    position: relative;
  }

  &-from {
    width: 599px;
    height: 450px;
    background: #171a21;
    border-radius: 23px 0 0 23px;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.login-img {
  height: 100%;
  width: 100%;
}

.regiter-guide {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;
  z-index: 10;
  font-size: 13px;

  p {
    color: #f2c992;

    span {
      color: $main;
      cursor: pointer;
    }
  }

  div {
    border-radius: 10px;
    background: #47220e;
    color: #f2c992;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    padding: 2px 0;

    span {
      color: white;
      cursor: pointer;
    }
  }
}

.login-from-box {
  width: 377px;
  margin: 0 auto;
}
</style>
