<template>
    <div class="loading" v-if="visible">
        <div class="loading-box">
            <div class="loading-img">
                <div class="laoding-flash"></div>
            </div>
            <img src="@/assets/pic/load.png" class="img" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.loading {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    // background: #16181f;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-box {
        background: #2e2e33;
        border-radius: 15px;
        width: 80px;
        height: 80px;
        transform: translate(-50%, -50%) rotate(45deg);
        color: white;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-img {
        width: 60px;
        height: 60px;
        background: $main;
        border-radius: 100%;
        position: absolute;
        overflow: hidden;
    }

    img {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        transform: rotate(-45deg);
    }
}

.laoding-flash {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: absolute;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: -20%;
        left: -30%;
        width: 120px;
        height: 120px;
        border-radius: 40%;
        background-color: white;
        animation: shi 3.6s linear infinite;
    }

    &::before {
        content: '';
        position: absolute;
        top: -20%;
        left: -25%;
        width: 120px;
        height: 120px;
        border-radius: 42%;
        background-color: $main1;
        animation: xu 3.6s linear infinite;
    }
}

@keyframes shi {
    0% {
        transform: translate(-46%, -46%) rotate(0deg);
    }

    100% {
        transform: translate(-46%, -46%) rotate(360deg);
    }

}

@keyframes xu {
    0% {
        transform: translate(-46%, -46%) rotate(0deg);
    }

    100% {
        transform: translate(-46%, -46%) rotate(360deg);
    }

}
</style>