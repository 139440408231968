<template>
  <header :class="$route.name">
    <div class="ag-wid-14 header">
      <div class="zl-slogin">
        <div class="zl-head-ym" v-if="baseInfo">
          唯一永久地址<br />
          {{ baseInfo[5].configValue }}
        </div>
        <img src="@/assets/pic/logo.png" class="logo" @click="openUrl('/')" />
      </div>
      <Notice
        v-if="$route.meta.layout !== 'activity' && newsList"
        :visible="$route.meta.layout !== 'activity'"
        :list="newsList"
      />
      <div class="header-right">
        <template v-if="$route.meta.layout !== 'activity'">
          <div class="sc" @click="addBookmark">
            <span class="ico-bookmark"></span>
            <p>收藏本站</p>
          </div>
        </template>
        <template v-else>
          <button class="el-button" @click="openUrl('/')">首页</button>
          <template v-if="!isLogin">
            <button class="el-button" @click="openLoginShow">登录</button>
            <button
              class="el-button el-button--primary"
              @click="openUrl('/register')"
            >
              注册
            </button>
          </template>
          <template v-else>
            <button
              class="el-button"
              @click="jumpWithdraw('/user/withdraw/index')"
            >
              取款
            </button>
            <button
              class="el-button el-button--primary margin-r"
              @click="$router.push('/user/deposit')"
            >
              存款
            </button>
          </template>
        </template>
        <div class="quit" v-if="isLogin" @click="logout">
          <span class="ico-logout"></span>
          <p>退出</p>
        </div>
      </div>

      <Bulletin
        v-if="ismsgShow && newsList"
        :visible.sync="ismsgShow"
        :listData="newsList"
      />
    </div>
  </header>
</template>
<script>
import { mapState } from "vuex";
import { removeToken } from "@/utils/token";
import Notice from "@/components/Notice.vue";
import Bulletin from "@/components/Bulletin.vue";
export default {
  components: {
    Notice,
    Bulletin,
  },
  name: "Header",
  data() {
    return {
      newsList: null,
      ismsgShow: false,
    };
  },
  computed: {
    ...mapState(["isLogin", "apliyPwd", "userInfo", "baseInfo"]),
  },
  mounted() {
    this.getTop50MessageList();
  },
  methods: {
    moveScroll() {
      clearInterval(this.timerText);
    },
    // 公告
    getTop50MessageList() {
      this.$Api.getTop50MessageList().then((res) => {
        if (res) {
          this.newsList = res.messageList.filter(
            (item) => item.messageChannel === 0
          );
          if (this.newsList && this.newsList.length) {
            // 设定24小时后重新开启公告通知弹窗
            const isDay = localStorage.getItem("messageAuto");
            if (!isDay && this.newsList.length) {
              this.ismsgShow = true;
            }
            if (isDay) {
              const targetDay = 24 * 60 * 60 * 1000; //一天秒数
              const isDYDay = new Date().getTime() - isDay; //获取当前日期是否超过一天
              if (isDYDay > targetDay) {
                localStorage.removeItem("messageAuto");
              }
            }
          }
        }
      });
    },
    // 跳转取款
    jumpWithdraw(val) {
      // 判断是否设置手机号码
      if (!this.userInfo.mobile || this.apliyPwd) {
        const textTip = !this.userInfo.mobile
          ? "手机号码"
          : !this.apliayPwd
          ? "取款密码"
          : "";
        this.$confirm(`为了取款安全，请先去设置${textTip}`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            if (!this.userInfo.mobile) {
              this.$router.push("/user/security/phone");
              return;
            }
            if (this.apliyPwd) {
              this.$router.push("/user/security/withdrawPwd");
            }
          })
          .catch(() => {});
      } else {
        this.$router.push(val);
      }
    },
    openLoginShow() {
      if (!this.isLogin) {
        this.$store.dispatch("setOpenLoginAc", true);
      }
    },
    // 退出
    logout() {
      this.$Api.logout().then((res) => {
        if (res) {
          removeToken();
          this.$store.dispatch("setUserInfoAc", false);
          this.$store.dispatch("setHasLoginAc", false);
        }
      });
    },
    // 收藏
    addBookmark() {
      let url = window.document.location.href;
      let title = document.title;
      try {
        window.external.addFavorite(url, title);
      } catch (e) {
        try {
          window.sidebar.addPanel(url, title);
        } catch (e) {
          this.$message({
            message: "加入收藏失败，请使用Ctrl+D进来添加",
            type: "warning",
          });
        }
      }
    },
  },
  destroyed() {
    clearTimeout(this.timerText);
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/css/theme.scss";

header {
  width: 100%;
  border-bottom: 1px solid #212121;
  background: #16181f;
  height: 86px;
  &.eden {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: none;
    border-bottom: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &-right {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .el-button {
      border: 1px solid #3e4353;
      padding: 12px 30px;
    }
    .margin-r {
      margin-right: 20px;
    }

    .sc {
      cursor: pointer;
      text-align: center;
      font-size: 18px;
      color: $main3;
      margin-right: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &:last-child {
        margin-right: 0;
      }
    }

    .quit {
      cursor: pointer;
      text-align: center;
      font-size: 18px;
      color: $main;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &:hover {
      color: $main;
    }

    p {
      font-size: 12px;
      margin-top: 4px;
    }
  }
}

.logo {
  height: 86px;
}
.zl-slogin {
  position: relative;
}
.zl-head-ym {
  position: absolute;
  font-size: 10px;
  left: 400px;
  top: 10px;
  opacity: 0.4;
  white-space: nowrap;
}
</style>
