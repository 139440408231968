<template>
  <section class="ag-wid-14 ag-layout">
    <section class="ag-layout-left">
      <template v-if="!isLogin">
        <div class="ag-regsiter" @click="openUrl('/register')">
          <img src="@/assets/pic/register-button2.webp" class="icon" />
          <p class="text">注册</p>
          <span class="hots"></span>
        </div>
        <div class="ag-login" @click="openLogin">登录</div>
      </template>
      <template v-else>
        <div class="account-info" v-if="userInfo">
          <span class="account">{{ userInfo.userName }}</span>
          <div class="userInfo-row">
            <img
              class="lv"
              :src="
                require('@/assets/pic/vip/lv' +
                  (userInfo.levelId > 6 ? 6 : userInfo.levelId) +
                  '.webp')
              "
            />
            <div class="message" @click="$router.push('/user/mail')">
              <span class="ico-notify"></span>
            </div>
          </div>
        </div>
        <div class="account-money">
          <div class="account-number">
            <div class="number" v-if="accountMoney">
              <!-- <span class="ico-usdt-circle"></span> -->
              ¥
              <span>{{
                accountMoney.userBalance
                  ? accountMoney.userBalance.toFixed(2)
                  : "0.00"
              }}</span>
            </div>
            <div class="refresh ico-refresh" @click="getUserBalance"></div>
          </div>
          <div class="account-opern">
            <ul>
              <li @click="jumpWithdraw('/user/deposit')">
                <span class="ico-deposit"></span>
                <p>存款</p>
              </li>
              <li @click="jumpWithdraw('/user/withdraw/index')">
                <span class="ico-withdraw"></span>
                <p>取款</p>
              </li>
              <li @click="$router.push('/user/transfer/index')">
                <span class="ico-shima"></span>
                <p>转账</p>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <div class="ag-layout-scroll">
        <div class="ag-menus" v-for="(item, index) in menus" :key="index">
          <ul>
            <li
              v-for="(row, iteInd) in item"
              :key="iteInd"
              @click="jumpWeb(row, index)"
              :class="{
                hover: targetMenu === row.layout || targetMenu === row.route,
              }"
            >
              <span class="icon" :class="[row.style, row.icon]"></span>
              <span class="text">{{ row.name }}</span>
              <img
                src="@/assets/pic/icon-high.webp"
                class="icon-hei"
                v-if="row.show"
              />
              <img
                src="@/assets/pic/icon-new.webp"
                class="icon-hei"
                v-if="row.type === 8"
              />
            </li>
          </ul>
          <div class="ag-menus-money" v-if="index === 0">
            <span class="ag-menus-hots"></span>
            <div class="ag-menus-number">
              <div
                class="ag-menus-number-item"
                v-for="(item, index) in moneyUSD"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>

              <div class="ag-menus-number-unit">美元</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <router-view class="ag-layout-center"></router-view>
    <section class="ag-layout-right">
      <div class="ag-brand">
        <a
          href="javascript:;"
          class="ag-brand-link"
          @click="$router.push('/brand')"
        >
          <img src="@/assets/pic/brand-2-2.webp" class="icon" />
          <div class="">
            <strong>品牌赞助</strong>
            <p>品牌沉淀 值得信赖</p>
          </div>
        </a>
        <a
          href="javascript:;"
          @click="$router.push('/user/vip')"
          class="ag-brand-link"
        >
          <img src="@/assets/pic/vip.webp" />
          <div class="">
            <strong>贵宾会</strong>
            <p>身份特权 成就巅峰</p>
          </div>
        </a>
      </div>
      <div class="ag-mouth">
        <div class="ag-mouth-row">
          <img src="@/assets/pic/advance-superman2.webp" />
          <span>每周一更新星级</span>
        </div>
        <div class="ag-mouth-tips" v-if="isLogin && userInfo">
          <span class="text-gray">开户并存款 </span>
          <span class="text-orange">
            升级
            {{ userInfo.nextLevelName }}</span
          >
        </div>
        <div class="ag-mouth-list" v-else>
          <div class="ag-mouth-item">
            <img src="@/assets/pic/icon0.png" /><span>生日红包</span>
          </div>
          <div class="ag-mouth-item">
            <img src="@/assets/pic/icon1.png" /><span>贵宾礼金</span>
          </div>
          <div class="ag-mouth-item">
            <img src="@/assets/pic/icon2.png" /><span>专属活动</span>
          </div>
          <div class="ag-mouth-item">
            <img src="@/assets/pic/icon3.png" /><span>专属客服</span>
          </div>
        </div>
        <div class="ag-mouth-link">
          <span @click="openLogin" v-if="!isLogin">登录查看</span>
          <span v-else class="view" @click="$router.push('/user/vip')"
            >查看权益 <i class="ico-arrow-right"></i
          ></span>
        </div>
      </div>
      <div class="zl-ranking">
        <div class="zl-ranking-title">
          <span>盈利排名</span>
        </div>
        <div class="zl-ranking-tab">
          <ul v-if="rankingList.length">
            <li
              v-for="(item, index) in rankingList"
              @click="rankIngIndex = index"
              :class="{ hover: index === rankIngIndex }"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="zl-ranking-list" v-if="rankingList[rankIngIndex]">
          <ul>
            <li
              v-for="(item, index) in rankingList[rankIngIndex].list"
              :key="index"
            >
              <span v-if="index <= 2">
                <img
                  :src="require('@/assets/pic/sort0' + (index + 1) + '.webp')"
              /></span>
              <span v-else> {{ index + 1 }}</span>
              <span>{{ item.name }}</span>
              <span>{{ item.money }}</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      targetMenu: "home",
      menus: [
        [
          {
            name: "首页",
            icon: "ico-home2",
            link: "/",
            type: 1,
            layout: "home",
          },
          {
            name: "最新优惠",
            icon: "ico-promotion",
            link: "/activity",
            type: 2,
            layout: "activity",
          },
        ],
        [],
        [
          // {
          //     name: '任务中心',
          //     icon: 'ico-mission',
          //     link: '',
          //     type: 7,
          //     layout: 'ranking'
          // },
          {
            name: "邀请好友",
            icon: "ico-invite",
            link: "/invite",
            type: 8,
            layout: "invite",
          },
          {
            name: "下载中心",
            icon: "ico-download",
            link: "/download",
            type: 9,
            layout: "download",
          },
          {
            name: "合作加盟",
            icon: "ico-franchise",
            link: "/franchise",
            type: 10,
            layout: "franchise",
          },
        ],
      ],
      loginMenus: [
        {
          name: "会员中心",
          icon: "ico-user-center",
          link: "/user/index",
          type: 7,
          style: "icon12",
          layout: "userInfo",
        },
        {
          name: "增删取款账户",
          icon: "ico-bankcard",
          link: "/user/bank/index",
          type: 13,
          style: "icon13",
          layout: "account",
        },
        {
          name: "账户安全",
          icon: "ico-security",
          link: "/user/security/index",
          type: 7,
          layout: "secure",
        },
        {
          name: "交易记录",
          icon: "ico-records",
          link: "/user/records/index",
          type: 8,
          layout: "records",
        },
        {
          name: "投注记录",
          icon: "ico-records",
          link: "/user/betRecord/index",
          type: 11,
          layout: "betRecord",
        },
        {
          name: "福利中心",
          icon: "ico-save-money",
          link: "/user/welfare/index",
          type: 12,
          layout: "welfare",
        },
      ],
      rankingList: [],
      rankIngIndex: 0,
      moneyUSD: "",
    };
  },
  computed: {
    ...mapState(["userInfo", "accountMoney", "isOpenLogin", "apliyPwd"]),
  },
  watch: {
    isLogin() {
      this.getUserInfo();
    },
  },
  mounted() {
    this.getApiGameCategoryList();
    this.getUserInfo();
    this.rankCustomList();
    this.menuInfo();
  },
  methods: {
    checkIsSetWidthPassword() {
      this.$Api.checkIsSetWidthPassword().then((res) => {
        if (res.msg === "no") {
          this.$store.dispatch("setApliyPwdAc", true);
        } else {
          this.$store.dispatch("setApliyPwdAc", false);
        }
      });
    },
    // 跳转取款
    jumpWithdraw(val) {
      // 判断是否设置手机号码
      if (!this.userInfo.mobile || this.apliyPwd) {
        const textTip = !this.userInfo.mobile
          ? "手机号码"
          : !this.apliayPwd
          ? "取款密码"
          : "";
        this.$confirm(`为了取款安全，请先去设置${textTip}`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            if (!this.userInfo.mobile) {
              this.$router.push("/user/security/phone");
              return;
            }
            if (this.apliyPwd) {
              this.$router.push("/user/security/withdrawPwd");
            }
          })
          .catch(() => {});
      } else {
        this.$router.push(val);
      }
    },
    // 导航栏初始化
    menuInfo() {
      this.targetMenu =
        this.$route.params.id ||
        this.$route.query.nav ||
        this.$route.meta.layout ||
        "home";
      let obj = Math.floor(Math.random() * 1000000);
      this.moneyUSD = obj.toLocaleString();
      setInterval(() => {
        const random = Math.floor(Math.random() * 100);
        obj = Number(obj) + Number(random);
        this.moneyUSD = obj.toLocaleString();
      }, 3000);
    },
    // 导航跳转
    jumpWeb(val, index) {
      if (index === 1) {
        if (["0", "2", "6"].includes(val.gameType)) {
          this.$router.push({
            path: "/gameList",
            query: {
              type: val.gameType,
              nav: val.route,
            },
          });
          return;
        }
        this.$router.push({
          name: "game",
          params: {
            id: val.route,
          },
        });
      } else {
        if (val.type === 8 && !this.isLogin) {
          this.$store.dispatch("setOpenLoginAc", true);
          return;
        }
        if (val.type === 13) {
          this.jumpWithdraw(val.link);
          return;
        }
        this.$router.push(val.link);
      }
    },
    // 排行定制今日、昨日、前日数据
    rankCustomList() {
      const dataName = ["今日", "昨日", "前日"];
      for (let i = 0; i < dataName.length; i++) {
        const obj = {
          name: dataName[i],
          list: this.rankRandomData(),
        };
        this.rankingList.push(obj);
      }
    },
    // 排行生成数据
    rankRandomData() {
      const arr = [];
      const gs = { style: "currency", currency: "CNY" };
      for (let i = 0; i < 20; i++) {
        const namex = this.rankIngRandom(10);
        const money = Math.floor(Math.random() * 1000000);
        const obj = {
          name: namex.slice(0, 1) + "***" + namex.slice(namex.length - 2),
          money: Number(money),
        };
        arr.push(obj);
      }
      // 排序
      arr.sort((a, b) => {
        return b.money - a.money;
      });
      // 转化成money格式
      arr.forEach((item) => {
        item.money = item.money.toLocaleString("zh-CN", gs);
      });
      return arr;
    },
    // 随机生成排行名称
    rankIngRandom(length) {
      var characters = "abcdefghijklmnopqrstuvwxyz0123456789"; // 包含所有可能的字符
      var name = "";
      for (var i = 0; i < length; i++) {
        var randomIndex = Math.floor(Math.random() * characters.length); // 获取随机索引值
        name += characters[randomIndex]; // 根据索引值从字符集中选择相应的字符并添加到名称中
      }
      return name;
    },
    // 账户money
    getUserBalance() {
      this.$loading.show();
      this.$Api.getUserBalance().then((res) => {
        if (res) {
          this.$loading.hide();
          this.$store.dispatch("setAccountMoneyAc", res.userBalanceMap);
        }
      });
    },
    // 个人资料接口
    getUserInfo() {
      const isLogin = localStorage.getItem("AppToken");
      if (!isLogin) {
        this.$store.dispatch("setUserInfoAc", null);
        this.$store.dispatch("setHasLoginAc", false);
        if (this.menus.length === 4) {
          this.menus.splice(2, 1);
        }
        return;
      }
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
          this.getUserBalance();
          this.checkIsSetWidthPassword();
          this.menus.splice(2, 0, this.loginMenus);
        }
      });
    },
    openLogin() {
      if (!this.isLogin) {
        this.$store.dispatch("setOpenLoginAc", true);
      }
    },
    // 游戏平台栏目
    async getApiGameCategoryList() {
      await this.$Api.getApiGameCategoryList().then((res) => {
        if (res) {
          // 设置图标
          const icons = [
            {
              type: "0",
              icon: "ico-fire", //热门
              route: "hot",
            },
            {
              type: "1",
              icon: "ico-realper", // 真人
              route: "realbet",
            },
            {
              type: "2",
              icon: "ico-game", //电子
              style: "icon11",
              route: "egame",
              show: true,
            },
            {
              type: "3",
              icon: "ico-tickets", //彩票
              route: "lottery",
            },
            {
              type: "4",
              icon: "ico-soccer", //体育
              route: "sport",
            },
            {
              type: "5",
              icon: "ico-dz", //电竞 --
              route: "gaming",
            },
            {
              type: "6",
              icon: "ico-by", //捕鱼 --
              route: "fishing",
            },
            {
              type: "7",
              icon: "ico-realper2", //棋牌
              route: "chess",
            },
          ];
          res.apiGameCategoryList.forEach((item) => {
            icons.find((row) => {
              if (row.type === item.gameType) {
                item.icon = row.icon;
                item.route = row.route;
                item.show = row.show || false;
                if (row.style) {
                  item.style = row.style;
                }
              }
            });
            item.type = item.gameType;
            item.name = item.gameTypeName;
          });
          this.menus[1].push(...res.apiGameCategoryList);
          this.$store.dispatch("setNavListAc", res.apiGameCategoryList);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/css/theme.scss";

.ag-layout {
  display: flex;
  overflow: hidden;
  margin: 24px auto 0;

  &-left,
  &-right {
    width: 212px;
    display: flex;
    flex-direction: column;
    height: auto;
    flex: none;
  }

  &-center {
    flex: 1;
    margin: 0 19px;
    min-width: 956px;
  }

  &-scroll {
    flex: 1;
    background: url("@/assets/pic/bar-bg4.webp") no-repeat $boxbg;
    background-position: center bottom 20px;
    background-size: 100% auto;
    padding-bottom: 100px;
  }
}

.ag-regsiter {
  background: #000;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:hover {
    &::after {
      background: linear-gradient(
        208deg,
        rgba(13, 17, 20, 0) 50%,
        #c44506 130%
      );
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(208deg, #c44506, rgba(13, 17, 20, 0) 76%);
    opacity: 0.4;
  }

  .icon {
    height: 36px;
  }

  .text {
    color: $main1;
    margin-top: 6px;
  }

  .hots {
    width: 52px;
    height: 52px;
    background: url("@/assets/pic/badge2.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.ag-login {
  height: 56px;
  font-size: 17px;
  color: #dedede;
  letter-spacing: -0.36px;
  background-color: #16181f;
  border: 1px solid #3e4353;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main1;
  margin: 4px 0;
  cursor: pointer;

  &:hover {
    border: 1px solid #61677e;
  }
}

.ag-menus {
  background: $boxbg;
  padding: 0 10px;

  // margin-top: 6px;
  &:last-child {
    ul {
      border-bottom: 0;
    }
  }

  ul {
    padding: 30px 20px;
    border-bottom: 1px solid #282b30;

    li {
      margin-bottom: 24px;
      height: 18px;
      color: $main3;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      .icon {
        margin-right: 10px;
        font-size: 18px;
        color: #787878;
        background-image: linear-gradient(0deg, #787878, #787878);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;

        &.icon11 {
          font-size: 11px;
        }

        &.icon12 {
          font-size: 13px;
        }

        &.icon13 {
          font-size: 15px;
        }
      }

      .icon-hei {
        height: 16px;
        margin-left: 6px;
      }

      .text {
        background-image: linear-gradient(0deg, #787878, #787878);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
      }

      &:hover,
      &.hover {
        .icon,
        .text {
          background-image: -webkit-linear-gradient(104deg, #a0511a, #ff7f27);
        }
      }
    }
  }
}

.ag-menus-money {
  background: url("@/assets/pic/jackpotPool_bg2.webp") no-repeat;
  background-size: 100% 100%;
  height: 80px;
  cursor: pointer;
  margin: 0 -10px;
  position: relative;
  padding: 21px 11px;
  padding-top: 43px;

  .ag-menus-hots {
    width: 47px;
    height: 38px;
    background: url("@/assets/pic/home-jp-share.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -13px;
    top: -6px;
  }
}

.ag-menus-number {
  display: flex;
  align-items: center;
  height: 24px;

  &-item {
    background: linear-gradient(180deg, #131418, #1c2026, #151619);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;

    span {
      background: linear-gradient(180deg, #f4edd4, #e8d498);
      background-clip: text;
      color: transparent;
      width: 18px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-unit {
    font-size: 12px;
    background: linear-gradient(180deg, #f4edd4, #e8d498);
    background-clip: text;
    color: transparent;
    margin-left: 3px;
  }
}

// right
.ag-brand {
  &-link {
    background: #000;
    height: 87px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 0 0 20px;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;
      background-image: linear-gradient(
        208deg,
        #c44506,
        rgba(13, 17, 20, 0) 76%
      );
      top: 0;
      left: 0;
      opacity: 0.1;
      z-index: 1;
    }

    img {
      height: 48px;
      margin-right: 16px;

      &.icon {
        height: 42px;
      }
    }

    strong {
      background-image: -webkit-linear-gradient(-90deg, #e8e8e8, #979797);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
    }

    p {
      font-size: 12px;
      color: #7e7e7e;
    }
  }
}

.ag-mouth {
  background: $boxbg;
  padding: 10px 10px 0;

  &-row {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 66px;
    }

    span {
      margin-left: 8px;
      font-size: 12px;
      color: #7e7e7e;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &-item {
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      margin-left: 6px;
      font-size: 12px;
      color: #a6a6a6;
      font-weight: 400;
    }
  }
}

.ag-mouth-link {
  width: 100%;
  height: 35px;
  border-top: 1px solid #282b30;
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    background: linear-gradient(180deg, #a0511a, #ff7f27);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    &.view {
      background-image: -webkit-linear-gradient(-90deg, #e8e8e8, #979797);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

// 登录后
.account-info {
  background: $boxbg;
  padding: 6px 16px;
  margin-bottom: 4px;

  .userInfo-row {
    display: flex;
    align-items: center;
  }

  .account {
    color: $main2;
    font-size: 13px;
    letter-spacing: -0.3px;
  }

  .lv {
    height: 15px;
  }

  .message {
    position: relative;
    font-size: 18px;
    color: $main;
    margin-left: 16px;
    cursor: pointer;
  }
}

.account-money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $boxbg;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 4px;

  .account-number {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: $main2;
  }

  .number {
    display: flex;
    align-items: center;

    span {
      margin-right: 4px;
    }
  }

  .refresh {
    color: $main4;
  }
}

.account-opern {
  width: 100%;
  margin: 8px 0 0;
  padding: 14px 0 0;
  // border-bottom: 1px solid #282b30;
  border-top: 1px solid #282b30;

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    padding: 0 10px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &:hover {
        span {
          background-image: -webkit-linear-gradient(-90deg, #a0511a, #ff7f27);
        }
      }

      span {
        background-image: -webkit-linear-gradient(-90deg, #ff7f27, #a0511a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #a0511a;
        font-size: 20px;
      }

      p {
        font-size: 14px;
        color: $main3;
        margin-top: 6px;
      }
    }
  }
}

.ag-mouth-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 12px;

  span {
    margin: 0 2px;
  }
}

.zl-ranking {
  background: $boxbg;
  margin-top: 6px;
  flex: 1;

  &-title {
    text-align: center;
    margin: 0 auto;
    background: url("@/assets/pic/sortbg.webp") no-repeat center;
    background-size: auto 100%;
    height: 30px;
    display: block;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      color: #7e7e7e;
    }
  }

  &-tab {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;

      li {
        font-size: 14px;
        color: $main4;
        position: relative;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.hover {
          color: white;
          border-bottom: 2px solid $main;
        }
      }
    }
  }

  &-list {
    padding: 0 10px 20px;

    ul {
      li {
        display: flex;
        align-items: center;

        span {
          font-size: 12px;
          flex: 1;
          display: flex;
          align-items: center;
          color: $main3;

          &:first-child {
            color: #787878;
            width: 36px;
            height: 32px;
            flex: none;
            margin-right: 5px;
            justify-content: center;
          }

          &:nth-child(2) {
            flex: none;
            width: 50px;
          }

          img {
            width: 28px;
          }
        }
      }
    }
  }
}
</style>
