<template>
    <div class="message" v-if="visible && list && list.length">
        <div class="home-ad-scroll" ref="scorlls" @click="openUrl('/notice')">
            <div class="home-ad-text" @mouseout.stop="scrollStar" @mouseover.stop="moveScroll" ref="scorllWid"
                :style="{ 'transform': 'translateX(' + scorllLeftVal + 'px)', 'transition': scrollLeftStar }">
                <div class="row" v-for="(item, index) in list" :key="index"
                v-html="item.scrollContent">
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
export default{
    props:{
        visible:{
            type:Boolean,
            default:false
        },
        list:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return{
            timerText:null,
            scorllLeftVal:0,
            scrollLeftStar:'all .4s',
            scorllWid:0,
            isScroll:false
        }
    },
    mounted(){
        this.getTop50MessageList()
    },
    methods:{
        moveScroll() {
            clearInterval(this.timerText)
        },
        // 公告
        getTop50MessageList() {
            if(this.list && this.list.length){
                this.$nextTick(() => {
                        this.scrollInit()
                    })
            }
        },
        // 跑马灯初始化
        scrollInit() {
            // 获取box宽度
            const dowSocrll = this.$refs.scorlls.clientWidth
            this.scorllWid = this.$refs.scorllWid.clientWidth
            const domClone = this.$refs.scorllWid
            this.isScroll = this.scorllWid > dowSocrll ? true : false;
            if (this.isScroll) {
                let dom = domClone.cloneNode(true)
                domClone.appendChild(dom)
                this.scrollStar()
            }
        },
        scrollStar() {
        if (!this.isScroll) return
        this.timerText = setInterval(() => {
            const valNumber = this.scorllWid - this.scorllLeftVal < 10 ? this.scorllWid - this.scorllLeftVal : 4
            this.scorllLeftVal -= valNumber
            if (this.scorllLeftVal === -this.scorllWid) {
                this.scorllLeftVal = 0
                this.scrollLeftStar = 'none'
                setTimeout(() => {
                    this.scrollLeftStar = 'all .4s'
                }, 50)
            }
        }, 120)
        },
    }
}
</script>
<style scoped lang="scss">

.message{
    flex: 1;
    margin-left: 30px;
}
.home-ad-scroll{
    overflow: hidden;
    width: 345px;
}
.home-ad-text{
    white-space: nowrap;
    display: inline-block;
    .row{
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: #a6a6a6;
        cursor: pointer;
    }
}
</style>